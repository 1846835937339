//获取积分商城列表
const PointsMallGoodsListUrl = `/gateway/hc-serve/manageapi/pointsMall/listPaging`;
//获取积分商城详情
const PointsMallGoodsDetail = `/gateway/hc-serve/manageapi/pointsMall/detailById`;
//增加|编辑积分商城
const PointsMallGoodsEdit = `/gateway/hc-serve/manageapi/pointsMall/updGoods`;
//上架 / 下架积分商品
const PointsMallGoodsUpdShelfStsStatus = `/gateway/hc-serve/manageapi/pointsMall/updShelfStsStatus`;
//删除积分商品信息
const PointsMallGoodsDeleteById = `/gateway/hc-serve/manageapi/pointsMall/deleteById`;

//获取积分商城订单列表
const PointsMallOrderListUrl = `/gateway/hc-serve/manageapi/pointsMallOrder/listPaging`;
//获取积分商城订单列表(导出用)
const PointsMallExportOrderListUrl = `/gateway/hc-serve/manageapi/pointsMallOrder/exportOrderList`;
//积分兑换订单-完成订单
const PointsMallOrderCompleted = `/gateway/hc-serve/manageapi/pointsMallOrder/orderCompleted`;

//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;
//获取商家列表
const getShopperListUrl = `/gateway/hc-serve/manageapi/pointsMallShop/listPaging`;
//导出订单
const exportOrderUrl = `/gateway/hc-serve/manageapi/pointsMallOrder/exportOrderListToExcel`;
//商户列表
const merchantListUrl = `/gateway/hc-serve/manageapi/pointsMallShop/getShopInfoList`;
//证件类型下拉框列表查询
const getInitInfoUrl = `/gateway/blade-system/userManagement/getInitInfo`;
//商户详情
const merchantDetailUrl = `/gateway/hc-serve/manageapi/pointsMallShop/detailById`;
//审核
const handleMerchantUrl = `/gateway/hc-serve/manageapi/pointsMallShop/updateStatus`;
//判断商户是否有上架商品
const judgeGoodUrl = `/gateway/hc-serve/manageapi/pointsMallShop/hasPutawayGoods`;
//商户编辑
const addOrEditShopUrl = `/gateway/hc-serve/manageapi/pointsMallShop/addOrEditShop`;
export {
  getInitInfoUrl,
  addOrEditShopUrl,
  merchantListUrl,
  getShopperListUrl,
  PointsMallGoodsListUrl,
  PointsMallGoodsDetail,
  PointsMallGoodsEdit,
  PointsMallGoodsUpdShelfStsStatus,
  getCommunityListUrl,
  PointsMallGoodsDeleteById,
  PointsMallOrderListUrl,
  PointsMallExportOrderListUrl,
  PointsMallOrderCompleted,
  exportOrderUrl,
  merchantDetailUrl,
  handleMerchantUrl,
  judgeGoodUrl,
};
